.survey-widget-custom-datepicker .DayPicker-Day {
  color: #212121 !important;
  width: 24px !important;
  height: 24px !important;
  outline: none !important;
}

.survey-widget-custom-datepicker .DayPicker-Day--selected {
  color: white !important;
  background: #23b7e5 !important;
}
