body {
  margin: 0 !important;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-y: auto;
  overflow-x: hidden;
}

:root {
  --vh: 100vh;
}

#root {
  /*min-height: 100vh;*/
  position: relative;
}

/*:focus {*/
/*  outline: 0;*/
/*}*/

/*[role=button]:focus-visible, a:focus-visible {*/
/*  !* SHOW BLUE RING WHEN ELEMENT IS FOCUSED BY KEYBOARD *!*/
/*  !*outline: #23b7e5 auto 2px;*!*/
/*  outline: thin dashed #23b7e5*/
/*}*/

/*ACCESSIBILITY*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

/*ANIMATIONS*/
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.pac-container {
  z-index: 1342;
}
