.sv_next_btn,
.sv_prev_btn,
.sv_complete_btn {
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  color: #ffffff;
  background-color: #007fff;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Arimo, Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
}

.sv_next_btn:hover,
.sv_complete_btn:hover {
  background-color: #0059b2;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  text-decoration: none;
}

.sv_prev_btn {
  order: 3;
  background: white;
  color: #555555;
  box-shadow: none;
}

.sv_next_btn {
  order: 2;
}

.sv_complete_btn {
  order: 1;
}

.sv_complete_btn:focus,
.sv_next_btn:focus,
.sv_prev_btn:focus {
  opacity: 0.4;
}

.sv_row .DayPicker-Day:focus {
  background: #007fff;
}

.sv_nav {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 1em;
}

/* CARD */
.sv_main {
  /*margin-top: 40px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 1rem;*/
}

/* TYPOGRAPHY SUBTITLE 2 */
.sv_q_title {
  margin: 20px 0;
}

.sv_page_title {
  margin-top: 20px;
  font-size: 1.56rem;
  font-weight: 300;
}

.sv_q_title span {
  float: none !important;
  font-size: 1.56rem;
  font-weight: 300;
}

.sv_row {
  margin-top: 20px;
}

.sv_q_description {
  font-size: 1.12rem;
  font-weight: 300;
  min-height: 1px;
}

.sv_row > div > div:last-child {
  margin-top: 20px;
}

.sv_header {
  margin-bottom: 20px;
}

.sv_container {
  font-family: 'Roboto', 'Arimo', 'Scheherazade', sans-serif;
}

.sv_container circle {
  cx: 11px !important;
}

.sv_container .MuiFormControl-root {
  padding-top: 10px !important;
}

.sv_container div[role='alert'] {
  display: flex;
  padding: 6px 16px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  color: rgb(97, 26, 21);
  background-color: rgb(253, 236, 234);
}

.sv_container div[role='alert']::before {
  content: '❗';
}

.sv_progress {
  margin-top: 10px;
  width: 100%;
  height: 4px;
  position: relative;
  background: rgba(35, 183, 229, 0.2);
}

.sv_progress_bar {
  height: 100%;
  background: #007fff;
  transition: width 1s;
}

.sv_progress span {
  display: none;
}

/* Fix budget icon cuts  */
.sv_row .sv_q.sv_qstn {
  overflow: initial;
}

.sv_qstn .MuiInputBase-root fieldset {
  border-style: solid !important;
  border-width: 1px !important;
}

.sv_qstn .MuiInputBase-root fieldset:hover,
.sv_qstn .Mui-focused fieldset {
  border-width: 2px !important;
}

.sv_qstn .MuiFormControlLabel-root {
  margin: 0px !important;
}
