/* Make sure that the autosuggest dropdown of Google Maps
   is always above other UI elements.
   We assume that it has to be higher then a MUI Modal
   see.: https://mui.com/material-ui/customization/z-index/
*/
.pac-container.pac-logo {
  z-index: 1400; /* use 'snackbar' z-index value 1) */
}

/*
1) If we would really want it to be foolproof we would somehow get this value from
MUI's theme. Problem is that The injected Google Maps UI elements our outside of the scope
of MUI's JSS styling system, so we can't really add the values from the MUI Theme.
Maybe we could add global CSS custom props to communicate this between MUI and 3rd
party plugins, but not worth the effort right now...
*/
